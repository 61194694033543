<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="人员姓名">
          <el-input v-model="realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="职位名称">
          <el-input v-model="posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所属组织">
          <el-input v-model="orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请日期">
          <el-input v-model="applicationDate" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开始时间">
          <el-input v-model="beginTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="结束时间">
          <el-input v-model="endTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="审批状态">
          <el-input v-model="bpmStatusName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="异常说明">
          <el-input v-model="reportReason" type="textarea" resize='none' :rows="5" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="img-url">
        <el-form-item label="异常报备资料">
          <el-image v-for="(item,index) in attachmentListJson" :preview-src-list="[item.picUrl+item.picPath]" :src="item.picUrl+item.picPath" :key="index"></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          realName: '', // 人员姓名
          posName: '', // 职位名称
          orgName: '', // 所属组织
          applicationDate: '', // 申请日期
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          bpmStatusName: '', // 审批状态
          reportReason: '', // 异常说明
          attachmentListJson: [], // 异常报备资料
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.realName = val.realName;
          this.posName = val.posName;
          this.orgName = val.orgName;
          this.applicationDate = val.applicationDate;
          this.beginTime = val.beginTime;
          this.endTime = val.endTime;
          this.bpmStatusName = val.bpmStatusName;
          this.reportReason = val.reportReason;
          this.attachmentListJson = val.attachmentListJson ? JSON.parse(val.attachmentListJson) : [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      realName: '', // 人员姓名
      posName: '', // 职位名称
      orgName: '', // 所属组织
      applicationDate: '', // 申请日期
      beginTime: '', // 开始时间
      endTime: '', // 结束时间
      bpmStatusName: '', // 审批状态
      reportReason: '', // 异常说明
      attachmentListJson: [], // 异常报备资料
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }
  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
